
<template>
<!-- 员工风采 -->
  <div class="about">
    <div class="nuox-about-header">
      <img src="http://39.104.95.82:8087/img/header/3.jpg" >
    </div>
    <div class="module-content module-content-news">
      <div class="nuox-breadcrumb">
        <span class="nuox-breadcrumb-item"><router-link tag="span" :to="$t('lang.headerMenus[0].pageSrc')">{{ $t("lang.headerMenus[0].name") }}</router-link></span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item" @click="returnList">{{ $t("lang.headerMenus[4].name") }}</span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item" @click="returnList">{{ $t("lang.headerMenus[4].childrens[2].name") }}</span>
        <span class="iconfont icon-dian" v-if="pageType=='detail'"></span>
        <span class="nuox-breadcrumb-item" v-if="pageType=='detail'&&$i18n.locale=='zh'" >正文</span>
        <span class="nuox-breadcrumb-item" v-if="pageType=='detail'&&$i18n.locale=='en'" >TEXT</span>
      </div>
      <div class="module-line"></div>
        <div :class="$i18n.locale=='en'?'module-nav-en':'module-nav'">
          <ul class="menu-vertical-nav">
            <li v-for="(item,index) in menus" :key="index" @click="checknav(item)">
              <div :class="'title '+[item.id==checkId?'open':'']" ><span v-html="item.name"></span><span :class="[item.id==checkId?'el-icon-arrow-down':'el-icon-arrow-right']+ ' icon-down'"></span></div>
            </li>
          </ul>
          <div class="nuox-advert">
              <img :src="item.imgSrc" v-for="(item,index) in adverts" :key="index" @click="advertTap(item)">
          </div>
        </div>
        <div class="nuox-module" v-if="pageType=='list'">
          <div class="nuox-content-title">
            <div class="title-name">
              {{ $t("lang.headerMenus[4].childrens[2].name") }}
              <div class="xian"></div>
            </div>
          </div>
          <div class="nuox-module-cooperate">
            <el-row v-if="news.length>0">
              <el-col :span="15">
                <div class="nuox-new-header-main"  @click="seeNews(news[currNewsIndex])">
                  <img :src="news[currNewsIndex].imgSrc">
                  <div class="new-header-info">
                    <div class="new-header-time">{{news[currNewsIndex].date}}</div>
                    <div  v-if="$i18n.locale=='zh'" class="new-header-name" v-html="news[currNewsIndex].commentZh"></div>
                    <div  v-if="$i18n.locale=='en'" class="new-header-name" v-html="news[currNewsIndex].commentEn"></div>
                  </div>
                </div>
              </el-col>
              <el-col :span="9">
                <div class="nuox-new-header-list">
                  <div class="new-header-item" v-for="(item,index) in news" :key="index" @click="seeNews(item)">
                    <div class="new-item-info">
                      <div class="nuox-news-item-time">{{item.date}}</div>
                      <div v-if="$i18n.locale=='zh'"  class="nuox-news-item-name" v-html="item.commentZh"></div>
                      <div v-if="$i18n.locale=='en'"  class="nuox-news-item-name" v-html="item.commentEn"></div>
                    </div>
                    <div class="pic-cover">
                      <img :src="item.imgSrc">
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="nuox-news-module" v-if="news.length>0">
                <div class="swiper-container mySwiper">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in news" :key="index"><img :src="item.imgSrc"></div>
                  </div>
                </div>
            </div>
            <div class="nuox-news-content-list">
              <div class="nuox-news-content-item" v-for="(item,index) in newAlls" :key="index">
                <div class="nuox-news-date">
                    <div class="nuox-news-day">{{item.day}}</div>
                    <div class="nuox-news-month">{{item.month}}</div>
                </div>
                <div class="news-content-left">
                  <img :src="item.imgSrc">
                </div>
                <div class="news-content-right">
                  <p class="tt" v-if="$i18n.locale=='zh'" v-html="item.titleZh"></p>
                  <p class="tt" v-if="$i18n.locale=='en'" v-html="item.titleEn"></p>
                  <p class="cc" v-if="$i18n.locale=='zh'" v-html="item.commentZh"></p>
                  <p class="cc" v-if="$i18n.locale=='en'" v-html="item.commentEn"></p>
                  <div class="news-more">
                   <button class="nuox-new-button" @click="seeNews(item)">{{$t('lang.button.one')}}</button>
                  </div>
                </div>
              </div>
            </div>
             <div class="nuox-page-center">
              <el-pagination
              :small="true"
                  :background="true"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNum"
                  :page-sizes="[10,30,50,70]"
                  :page-size="pageCount"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalCount">
                </el-pagination>
              </div>
          </div>
        </div>
        <div class="nuox-module" v-if="pageType=='detail'">
            <div class="nuox-article-title" v-if="$i18n.locale=='en'" v-html="item.titleEn" ></div>
            <div class="nuox-article-title" v-if="$i18n.locale=='zh'" v-html="item.titleZh" ></div>
            <div class="nuox-article-author" v-if="$i18n.locale=='en'" ><span v-html="item.date"></span><span v-html="item.authorEn"></span></div>
            <div class="nuox-article-author" v-if="$i18n.locale=='zh'" ><span v-html="item.date"></span><span v-html="item.authorZh"></span></div>
            <div class="nuox-article-content" v-if="$i18n.locale=='en'" v-html="item.contentEn"></div>
            <div class="nuox-article-content" v-if="$i18n.locale=='zh'" v-html="item.contentZh"></div>
        </div>
      <div class="module-line"></div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper/swiper-bundle.min.js"
import "swiper/swiper-bundle.min.css"
export default {
  computed:{
    menus:function(){
      return this.$t('lang.headerMenus[4].childrens');
    }
  },
  data:function() {
    return {
        checkId:43,
        checkChildrenId:0,
        pageNum:1,
        pageCount:10,
        totalCount:0,
        newAlls:[],
        news:[],
        currNewsIndex:0,
        pageType:'list',
        item:{
          contentEn:'',
          contentZh:'',
          titleEn:'',
          titleZh:'',
          authorEn:'',
          authorZh:'',
          date:'',
        },
        adverts:[]
    };
  },
  mounted(){
    var id = this.$route.query.id;
     if(id){
      this.pageType='detail';
      this.initInfoById(id);
     }else{
      this.init('ZY');
      this.init('');
     }
    this.advert();
    /*window.setInterval(function(){
      console.log("定时轮播");
    }, 3000);*/
  },
  watch:{
      pageType(val, oldVal){//普通的watch监听
        if(val=='list'){
            this.init('ZY');
          this.init('');
        }
      }
  },
  methods:{
    async init(type){
      var that = this;
      var baseUrl = this.$baseURL;
      const { data: res } = await this.$http.get(
        "webSite/new_personnel?pageNum="+that.pageNum+"&pageCount="+that.pageCount+"&type="+type
      );
      if(res.code==0){
        var data = res.data.data;
        that.totalCount = res.data.count;
        if(type!='ZY'){
          var newAlls = [];
          for(var key in data){
              var imgSrc = '';
              if(data[key].attList&&data[key].attList.length>0){
                imgSrc = baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName
              }
              var new1 = {};
              new1.commentZh = that.$getHtmlToText(data[key].personnelDesZh);
              new1.commentEn =  that.$getHtmlToText(data[key].personnelDesEn);
              new1.titleZh =  data[key].personnelNameZh;
              new1.titleEn =  data[key].personnelNameEn;
              new1.authorZh =  data[key].authorNameZh;
              new1.authorEn =  data[key].authorNameEn;
              new1.contentZh = data[key].personnelDesZh;
              new1.contentEn = data[key].personnelDesEn;
              new1.date =  data[key].personnelDate;
              new1.day = that.$moment(new1.date).format('DD');
              new1.month = that.$moment(new1.date).format('YYYY-MM');
              new1.imgSrc = imgSrc;
              newAlls.push(new1);
          }
          that.newAlls= newAlls;
        }else{
        //重要新闻
        var news = [];
        for(var key in data){
            var imgSrc = '';
            if(data[key].attList&&data[key].attList.length>0){
               imgSrc = baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName
            }
            var new1 = {};
            new1.commentZh = that.$getHtmlToText(data[key].personnelDesZh);
            new1.commentEn =  that.$getHtmlToText(data[key].personnelDesEn);
            new1.titleZh =  data[key].personnelNameZh;
            new1.titleEn =  data[key].personnelNameEn;
            new1.authorZh =  data[key].authorNameZh;
              new1.authorEn =  data[key].authorNameEn;
              new1.contentZh = data[key].personnelDesZh;
              new1.contentEn = data[key].personnelDesEn;
            new1.date =  data[key].personnelDate;
            new1.imgSrc = imgSrc;
            news.push(new1);
        }
        that.news= news;
        setTimeout(() =>{
          new Swiper(".mySwiper",{
              slidesPerView: 4,
              spaceBetween: 20,
              loop:true, //循环
              speed: 100,
              autoplay: true,
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
          })
        },1000);
      }
      }
    },
    async initInfoById(id){
      var that = this;
      var baseUrl = this.$baseURL;
      const { data: res } = await this.$http.get(
        "webSite/new_personnel_by_id?id="+id
      );
      if(res.code==0){
        var data = res.data.data;
        var imgSrc = '';
            if(data.attList&&data.attList.length>0){
               imgSrc = baseUrl+data.attList[0].attPath+data.attList[0].attRealName
            }
            var new1 = {};
            new1.commentZh = that.$getHtmlToText(data.personnelDesZh);
            new1.commentEn =  that.$getHtmlToText(data.personnelDesEn);
            new1.titleZh =  data.personnelNameZh;
            new1.titleEn =  data.personnelNameEn;
            new1.authorZh =  data.authorNameZh;
              new1.authorEn =  data.authorNameEn;
              new1.contentZh = data.personnelDesZh;
              new1.contentEn = data.personnelDesEn;
            new1.date =  data.personnelDate;
            new1.imgSrc = imgSrc;
            that.item=new1;
      }
    },
    async advert(){//加载广告
      var that = this;
       var baseUrl = this.$baseURL;
      const { data: res } = await this.$http.get(
        "webSite/nuox_advert?pageNum=1&pageCount=3"
      );
      if(res.code==0){
        var data = res.data.data;
        var adverts =[];
        for(var key in data){
          var advert = {};
          var imgSrc = '';
              if(data[key].attList&&data[key].attList.length>0){
                imgSrc = baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName
              }
          advert.imgSrc = imgSrc;
          advert.href = data[key].advertAddress;
          adverts.push(advert);
        }
        that.adverts=adverts;
      }
    },
    checknav(e){
      if(e.pageSrc){
          this.$router.push({ 
            path: e.pageSrc,
            query: {}
          })
      }
      this.checkId = e.id;
    },
    checknavchildren(item,item2){
      this.checkId=item.id;
      this.checkChildrenId=item2.id;
    },
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageCount=val;
        this.init('');
    },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNum=val;
        this.init('');
    },seeNews(e){
      this.item = e;
      this.pageType = 'detail';
    },returnList(){
      this.pageType = 'list';
    }, advertTap(e){
      console.log(e);
      window.location.href=e.href;
    },
  }
}
</script>
<style>
@import '../../assets/css/about.css';
@import '../../assets/css/news.css';
</style>